import {fetchCountries, countryFromIP} from '@/pages/frontpage/user_registration';

export function ready() {
  document.querySelector(`[js-accounging-offer-open]`)
    ?.addEventListener(`click`, () => alertify.accountingOfferDialog(``).resizeTo(`700px`, `700px`));

  AccountingOffer.createAccoutingOfferDialog();
}

class AccountingOffer {
  static createAccoutingOfferDialog() {
    if (alertify.accountingOfferDialog) return;

    alertify.dialog(`accountingOfferDialog`, function factory() {
      return {
        main: function() {
          this.message = I18n.t(`loading`);
        },
        setup: function() {
          return {
            buttons: [
              {
                text: I18n.t(`cancel`),
                key: 27 /*Esc*/
              },
            ],
            options: {
              title: I18n.t(`accounting_offer.title`),
              movable: true,
              resizable: true,
              closable: true,
              maximizable: true,
              modal: false,
            }
          };
        },
        prepare: function() {
          const offerForm = new AccountingOffer();
          this.setContent(offerForm.render());
          offerForm.ready();
        },
      };
    });
  }

  constructor() {
    this.offerFields = [
      {key: `purchases`, monthly: `monthly_purchases`, yearly: `yearly_purchases`, type: `papers`},
      {key: `sales`, monthly: `monthly_sales`, yearly: `yearly_sales`, type: `papers`},
      {key: `salaries`, monthly: `monthly_salaries`, yearly: `yearly_salaries`, type: `papers`},
      {key: `bank_transactions`, monthly: `monthly_bank_transactions`, yearly: `yearly_bank_transactions`, type: `papers`},
      {key: `accountor_cost`, monthly: `monthly_accountor_cost`, yearly: `yearly_accountor_cost`, type: `costs`},
      {key: `auditor_cost`, monthly: `monthly_auditor_cost`, yearly: `yearly_auditor_cost`, type: `costs`},
    ];

    this.data = {
      email: ``,
      phone: ``,
      company_name: ``,
      organization_nr: ``,
      contact_person: ``,
      comment: ``,
      monthly_purchases: 0,
      yearly_purchases: 0,
      monthly_sales: 0,
      yearly_sales: 0,
      monthly_salaries: 0,
      yearly_salaries: 0,
      monthly_bank_transactions: 0,
      yearly_bank_transactions: 0,
      monthly_accountor_cost: 0,
      yearly_accountor_cost: 0,
      monthly_auditor_cost: 0,
      yearly_auditor_cost: 0,
    };
  }

  ready() {
    this.html_obj = $(`.js-accounting-offer`);
    this.html_obj.on(`change`, this.getData.bind(this));
    this.html_obj.find(`.js-send-offer`).on(`click`, this.send.bind(this));
    this.initCountryPhoneCodeInput();
  }

  initCountryPhoneCodeInput() {
    const phoneField = this.html_obj.find(`[data-key="phone"]`);
    Promise
      .all([fetchCountries(), countryFromIP()])
      .then(([countries, current_country_code]) => {
        if (!phoneField.hasClass(`cc-has-picker`)) {
          new CountryPhoneCodePicker({
            input: phoneField,
            countries: countries,
            initSelect: current_country_code,
          });
        }
      });
  }

  getData(e) {
    const element = e.target;
    const data = {
      key: $(element).attr(`data-key`),
      value: $(element).val(),
      element: $(element),
    };
    this.actionMap(data);
  }

  actionMap({key, value}) {
    switch (key) {
      case `email`:
      case `phone`:
      case `company_name`:
      case `organization_nr`:
      case `contact_person`:
      case `comment`:
        this.setData(key, value);
        this.setField(key, value);
        break;
      case `monthly_purchases`:
      case `monthly_sales`:
      case `monthly_salaries`:
      case `monthly_bank_transactions`:
      case `monthly_accountor_cost`:
      case `monthly_auditor_cost`: {
        const amount = Math.abs(currencyFormatToNumber(value));
        this.calculateOpposite(`yearly`, key, amount);
        this.setData(key, amount);
        this.setField(key, amount);
        break;
      }
      case `yearly_purchases`:
      case `yearly_sales`:
      case `yearly_salaries`:
      case `yearly_bank_transactions`:
      case `yearly_accountor_cost`:
      case `yearly_auditor_cost`: {
        const amount = Math.abs(currencyFormatToNumber(value));
        this.calculateOpposite(`monthly`, key, amount);
        this.setData(key, amount);
        this.setField(key, amount);
        break;
      }
      default:
        break;
    }
  }

  calculateOpposite(type, key, value) {
    const findBy = type === `monthly` ? `yearly` : `monthly`;
    const field = this.offerFields.find(field => field[findBy] === key);
    const oppositeKey = field[type];
    const oppositeValue = (type === `monthly` ? value / 12 : value * 12).round();
    this.setField(oppositeKey, oppositeValue);
    this.setData(oppositeKey, oppositeValue);
  }

  // from html to object
  setData(key, value) {
    this.data[key] = value;
  }

  // from object to html
  // each field should have same data-key as in javascripts object
  setField(key, value) {
    this.html_obj.find(`[data-key="${key}"]`).val(value);
  }

  get isValidEmail() {
    const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const email = this.data.email;
    return email.length > 0 ? EMAIL_REGEX.test(email) : true;
  }

  get isValidPhone() {
    const PHONE_REGEX = /^\d+$/;
    const phone = this.data.phone;
    return PHONE_REGEX.test(phone);
  }

  get allAmountFieldsEmpty() {
    return !this.offerFields.some(field => (this.data[field.monthly] || this.data[field.yearly]) > 0);
  }

  send() {
    // TODO: Split css class toggling to a different function since this function should concern ajax call only
    this.html_obj.find(`[data-key="email"]`).toggleClass(`invalid`, !this.isValidEmail);
    this.html_obj.find(`[data-key="phone"]`).toggleClass(`invalid`, !this.isValidPhone);
    this.html_obj.find(`#phone_empty`).toggleClass(`invisible`, this.isValidPhone);
    this.html_obj.find(`#all_fields_are_zero`).toggleClass(`invisible`, !this.allAmountFieldsEmpty);

    if (!this.isValidEmail || !this.isValidPhone || this.allAmountFieldsEmpty) return;

    const countryCode = this.html_obj.find(`[name="country_phone_code"]`).val();
    if (!countryCode) return;

    const fullPhone = this.data.phone ? `${ countryCode }${ this.data.phone }` : ``;

    $.ajax({
      url: `/admin/accounting_offers`,
      type: `POST`,
      data: {
        accounting_offer: {
          ...this.data,
          phone: fullPhone,
        },
      },
      success: data => {
        alertify.closeAll();
        alertify.alert(`FakturaBank`, I18n.t(`accounting_offer.successfully_saved`));
      },
    });
  }

  render() {
    return `
      <div class="js-accounting-offer accounting-offer">
        <table>
          <thead>
            <tr>
              <th class="w-full-name"></th>
              <th colspan="2" class="text-c">${ I18n.t(`accounting_offer.count_of_journals`) }</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <h4>${ I18n.t(`accounting_offer.register_papers`) }:</h4>
              </th>
              <th class="w-amount">${ I18n.t(`accounting_offer.per_month`) }</th>
              <th class="w-amount">${ I18n.t(`accounting_offer.per_year`) }</th>
            </tr>
            ${ this.renderOffers(`papers`) }
            <tr>
              <th colspan="3">
                <h4>${ I18n.t(`accounting_offer.costs`) }:</h4>
              </th>
            </tr>
            ${ this.renderOffers(`costs`) }
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>${ I18n.t(`accounting_offer.company_name`) }</td>
              <td colspan="2">
                <input type="text" data-key="company_name" />
              </td>
            </tr>
            <tr>
              <td>${ I18n.t(`accounting_offer.organization_nr`) }</td>
              <td colspan="2">
                <input type="text" data-key="organization_nr" />
              </td>
            </tr>
            <tr>
              <th colspan="3">
                <h4>${ I18n.t(`accounting_offer.your_info`) }:</h4>
              </th>
            </tr>
            <tr>
              <td>${ I18n.t(`accounting_offer.contact_person`) }</td>
              <td colspan="2">
                <input type="text" data-key="contact_person" />
              </td>
            </tr>
            <tr>
              <td>${ I18n.t(`accounting_offer.email`) }</td>
              <td colspan="2">
                <input type="email" data-key="email" />
              </td>
            </tr>
            <tr>
              <td>${ I18n.t(`accounting_offer.phone`) }</td>
              <td colspan="2">
                <input type="text" data-key="phone" />
              </td>
            </tr>
            <tr>
              <td colspan="3">${ I18n.t(`accounting_offer.comment`) }</td>
            </tr>
            <tr>
              <td colspan="3" class="border-box">
                <textarea data-key="comment" class="resize-vertical w-full"></textarea>
              </td>
            </tr>
          </tbody>
        </table>

        <div id="phone_empty" class="text-error invisible">${ I18n.t(`accounting_offer.must_enter_phone`) }</div>

        <div id="all_fields_are_zero" class="text-error invisible">${ I18n.t(`accounting_offer.all_fields_are_zero`) }</div>

        <button class="js-send-offer buttons-standard">${ I18n.t(`accounting_offer.send`) }</button>
      </div>
    `;
  }

  renderOffers(type) {
    return this
      .offerFields
      .filter(field => field.type === type)
      .map(field => this.renderOfferRow(field))
      .join(``);
  }

  renderOfferRow(field) {
    return `
      <tr>
        <td>${ I18n.t(`accounting_offer.${ field.key}`) }</td>
        <td>
          <input
            type="text"
            data-key="${ field.monthly }"
            class="w-amount"
            value="${ this.data[field.monthly] }"/>
        </td>
        <td>
          <input
            type="text"
            data-key="${ field.yearly }"
            class="w-amount"
            value="${ this.data[field.yearly] }" />
        </td>
      </tr>
    `;
  }
}
