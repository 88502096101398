import {isInViewport} from '@/functions/dom';

export function ready() {
  $(window).on(`scroll`, scrollEvent);
}

function scrollEvent() {
  const registrationForm = document.querySelector(`.registeruser`);
  if (isInViewport(registrationForm)) {
    $(window).off(`scroll`, scrollEvent);
    prepareRegistationForm();
  }
}

function prepareRegistationForm() {
  Promise
    .all([fetchCountries(), countryFromIP()])
    .then(([countries, current_country_code]) => {
      const submitButton = document.querySelector(`.form-reg .registerbutton`);

      if (!$(`#new_user_cellphone`).hasClass(`cc-has-picker`)) {
        let phone_code = $(`#new_user_phone_code`);
        let phone_country = $(`#new_user_cellphone_country_id`);

        new CountryPhoneCodePicker({
          input: $(`#new_user_cellphone`),
          countries: countries,
          initSelect: current_country_code,
          select: (country) => {
            phone_code.val(country.phone_code);
            phone_country.val(country.id);
          },
        });

        initCountryAutocomplete($(`#country-autocomplete`), countries, current_country_code);
      }

      submitButton.textContent = I18n.t(`frontpage.register`);
      submitButton.disabled = false;
    });
}

export function fetchCountries() {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `/account/fetch_countries`,
      type: `GET`,
      success: resolve,
      error: () => {
        const phoneErrorBox = document
          .querySelector(`.form-reg .register-cellphone`);
        const countryErrorBox = document
          .querySelector(`.form-reg .register-country`);
        phoneErrorBox.textContent = I18n.t(`frontpage.fetch_cellphone_countries_failed`);
        countryErrorBox.textContent = I18n.t(`frontpage.fetch_cellphone_countries_failed`);
        document
          .querySelector(`.form-reg .registerbutton`)
          .textContent = I18n.t(`something_went_wrong`);
        reject();
      },
    });
  });
}

export function countryFromIP() {
  return new Promise((resolve, _reject) => {
    if (process.env.RAILS_ENV === `test`) {
      resolve(`NO`);
      return;
    }

    $.ajax({
      url: `https://ipapi.co/json/`,
      type: `GET`,
      success: (ip_data) => {
        resolve(ip_data.country);
      },
      error: () => {
        resolve(`NO`);
      },
    });
  });
}

function initCountryAutocomplete(element, countries, country_code) {
  // Need this to prevent register form submit when enter is pressed on country auto complete
  element.keydown((event) => {
    let key_press = event.keyCode;
    if (key_press == 13) {
      event.preventDefault();
      return false;
    }
  });

  new Autocomplete({
    input: element,
    mustBeSelected: true,

    initSelect: () => {
      let selected = countries.find((country) => {
        return country.code == country_code;
      });

      if (selected) {
        $(`#new_unit_country_id`).val(selected.id);
        return selected;
      } else {
        return false;
      }
    },
    source: (request, response) => {
      let term = request.term.toLowerCase();
      let filter = countries.filter((country) => country.name.toLowerCase().includes(term));
      response(filter);
    },
    renderItem: (country) => country.name,
    focus: (country) => country.name,
    select: (_, country) => $(`#new_unit_country_id`).val(country.id),
    empty: () => $(`#new_unit_country_id`).val(``),
  });
}
