import {ready as userReady} from '@/pages/frontpage/user_registration';
import {ready as accountingReady} from '@/pages/frontpage/accounting_offer';
import {onDocumentReady} from '@/functions/dom/';

const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

onDocumentReady(() => {
  $(`#email`).trigger(`focus`);
  $(`#menu>li:last-child>a`).on(`click`, () => { $(`#email`).trigger(`focus`); });
  $(`#sticky`).sticky({topSpacing: 0});
  $(`a[href*="#"]:not([href="#"])`).on(`click`, (event) => {
    if (location.pathname.replace(/^\//, ``) == event.target.pathname.replace(/^\//, ``) && location.hostname == event.target.hostname) {
      let target = $(event.target.hash);
      target = target.length ? target : $(`[name=` + event.target.hash.slice(1) + `]`);
      if (target.length) {
        $(`html,body`).animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });

  $(`.form-reg`).on(`submit`, (event) => {
    let errors = 0;
    let name = $(`#new_user_first_name`).val();
    let surname = $(`#new_user_last_name`).val();
    let cellphone = $(`#new_user_cellphone`).val();
    let address = $(`#new_unit_address`).val();
    let zip = $(`#new_unit_zip`).val();
    let city = $(`#new_unit_city`).val();
    let country = $(`#new_unit_country_id`).val();
    let login = $(`#new_user_login`).val();
    let password = $(`#new_user_password`).val();
    let confirmation = $(`#new_user_password_confirmation`).val();

    const form = $(`.form-reg`);
    const error_name = form.find(`.register-name`);
    const error_surname = form.find(`.register-surname`);
    const error_cellphone = form.find(`.register-cellphone`);
    const error_address = form.find(`.register-address`);
    const error_zip = form.find(`.register-zip`);
    const error_city = form.find(`.register-city`);
    const error_country = form.find(`.register-country`);
    const error_login = form.find(`.register-login`);
    const error_password = form.find(`.register-password`);
    const error_confirmation = form.find(`.register-confirmation`);

    error_name.text(``);
    error_surname.text(``);
    error_cellphone.text(``);
    error_address.text(``);
    error_zip.text(``);
    error_city.text(``);
    error_country.text(``);
    error_login.text(``);
    error_password.text(``);
    error_confirmation.text(``);

    if (name.length == 0) {
      $(error_name).text(I18n.t(`name_must_be_entered`));
      errors++;
    }

    if (surname.length == 0) {
      $(error_surname).text(I18n.t(`last_name_must_be_entered`));
      errors++;
    }

    if (cellphone.length == 0) {
      $(error_cellphone).text(I18n.t(`cellphone_must_be_entered`));
      errors++;
    }

    if (address.length == 0) {
      $(error_address).text(I18n.t(`address_must_be_entered`));
      errors++;
    }

    if (zip.length == 0) {
      $(error_zip).text(I18n.t(`zip_must_be_entered`));
      errors++;
    }

    if (city.length == 0) {
      $(error_city).text(I18n.t(`city_must_be_entered`));
      errors++;
    }

    if (country.length == 0) {
      $(error_country).text(I18n.t(`country_must_be_entered`));
      errors++;
    }

    if (login.length == 0 || !email_regex.test(login)) {
      let text = I18n.t(`login_must_be_entered`) + ` <span class="register-example">example@domain.com</span>`;
      $(error_login).html(text);
      errors++;
    }

    if (password.length < 6) {
      $(error_password).text(I18n.t(`password_is_too_short`));
      errors++;
    }

    if (password != confirmation) {
      $(error_confirmation).text(I18n.t(`passwords_dont_match`));
      errors++;
    }

    if (errors > 0) {
      event.preventDefault();
    }
  });

  $(`#work_positions form`).on(`submit`, (event) => {
    const form = $(`#work_positions form`);
    const name = form.find(`input#work_position_application_name`);
    const phone = form.find(`input#work_position_application_phone`);
    const email = form.find(`input#work_position_application_email`);
    const file_document = form.find(`input#work_position_application_document`);

    let errors = [];
    form.find(`input`).removeClass(`invalid`);

    if (!name.val().length) errors.push(name);
    if (!phone.val().length) errors.push(phone);
    if (!email.val().length || !email_regex.test(email.val())) errors.push(email);
    if (!file_document.val().length) errors.push(file_document);

    errors.forEach((el) => {
      $(el).addClass(`invalid`);
      event.preventDefault();
    });
  });
});

onDocumentReady(userReady);
onDocumentReady(accountingReady);
onDocumentReady(createPriceCalculatorDialog);

function createPriceCalculatorDialog() {
  if (!alertify.priceCalculatorDialog) {
    alertify.dialog(`priceCalculatorDialog`, function factory() {
      return {
        main: function() {
          this.header = I18n.t(`frontpage.pricing_calculator`);
        },
        setup: function() {
          return {
            buttons: [
              {
                text: `OK`
              },
            ],
            options: {
              resizable: true,
              movable: true,
              closable: true,
              maximizable: true
            },
          };
        },
        prepare: function() {
          $(this.elements.content).addClass(`js-price-calculator-content`);
          this.setHeader(this.header);
          this.setContent(``);
        },
        callback: function(closeEvent) {
          // for some reason chrome browser is closing the alertify when you have credentials
          // remembered for price calculator email and password fields
          if (event.type == `keyup` && (event.target.id == `email` || event.target.id == `password`)) {
            closeEvent.cancel = true;
          }
        }
      };
    });
  }
}
